import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import LeftComponent from "../LeftSideComponent/Index";
import RightComponent from "./RightSideComponent/Index";
import useMediaQuery from "@mui/material/useMediaQuery";

const Index = () => {
    const isMobile = useMediaQuery("(max-width:899px)");
    const initialFetchDone = useRef(false);
    const [uid, setUid] = useState("");
    const [loading, setLoading] = useState(false);
	const [emailOpen,setEmailOpen]=useState(false);
    function generateUniqueKey() {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const language = navigator.language;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Additional attributes
        const screenResolution = `${window.screen.width}x${window.screen.height}`;
        const colorDepth = window.screen.colorDepth;

        // Combine attributes
        const rawKey = `${userAgent}-${platform}-${language}-${timezone}-${screenResolution}-${colorDepth}`;

        // Hash the combined string to get a unique key
        return hashString(rawKey);
    }

    const hashString = (str) => {
        let hash = 0,
            i,
            chr;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString(16);
    };
	const saveUID = async () => {
		const uniqueKey = generateUniqueKey();
		const storedKey = localStorage.getItem("uniqueKey");
		if (!storedKey || storedKey !== uniqueKey) {
		  localStorage.setItem("uniqueKey", uniqueKey);
		  try {
			// Send the unique key to the backend
			setLoading(true)
			const response = await axios.post(`${process.env.REACT_APP_API_URL}save-uid`, {
			  uid: uniqueKey,
			});
	  
			console.log("UID saved:", response.data.uid); // Log success message
		  } catch (error) {
			console.error("Error saving UID:", error); // Catch and log any error
		  } finally {
			setLoading(false) // This will run regardless of success or failure
		  }
		} else {
		  //console.log("UID already exists in localStorage:", storedKey); // Log if UID is already stored
		}
	  };
    useEffect(() => {
        if (!initialFetchDone.current) {
            saveUID();
            initialFetchDone.current = true;
        }
    }, []);
    return (
		
        <Grid
            sx={{
                height: isMobile?"":"100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Container
                maxWidth={"lg"}
                style={{
                    padding: "0px",
                    boxShadow: "0px 4px 90.5px 0px #3DCED91A",
                    background: "white",
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} style={{flexDirection:isMobile?'column-reverse':''}} >
                        <Grid
                            item xs={12} md={4} lg={4} sx={{ borderRight: "2px solid #00000040", }}
                        >
                            <LeftComponent />
                        </Grid>
                        <Grid
                            item xs={12} md={8} lg={8} className="fadIN" sx={{ position: "relative" }}
                        >
                            <RightComponent loading={loading} setLoading={setLoading} emailOpen={emailOpen} setEmailOpen={setEmailOpen}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Grid>
    );
};

export default Index;