import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MDEditor from "@uiw/react-md-editor";
import CircularProgress from "@mui/material/CircularProgress";
import ContactRightSide from "../../Contact/RightSideComponent/Index";
import useMediaQuery from "@mui/material/useMediaQuery";

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#D7E1F3",
                        "--TextField-brandBorderHoverColor": "#D7E1F3",
                        "--TextField-brandBorderFocusedColor": "#D7E1F3",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                        borderRadius: "4px",
                    },
                    root: {
                        "& #filled-basic-email": {
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "22px",
                            color: "#7b7b7b",
                            padding: "12px 19px",
                            background: "#FAFCFF",
                        },
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            border: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });
const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "unset",
    borderRadius: "unset",
    background: "none",
}));
const Index = ({ loading, setLoading, emailOpen, setEmailOpen }) => {
    const isMobile = useMediaQuery("(max-width:899px)");
    const isSmLaptop = useMediaQuery("(max-width:1240px)");
    const outerTheme = useTheme();
    const [isChecked, setIsChecked] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [conversationHistory, setConversationHistory] = useState([
        {
            role: "system",
            content: `
        You are a software development cost estimator designed to guide users through a detailed exploration of their software project requirements. Start by asking the user to specify the primary category of their project . For each response, your next question should delve deeper by asking for a subcategory or more specific details related to the chosen category. For each response, ask more detailed or specific questions related to that category. If the user selects multiple categories, you must ask questions for each category independently, ensuring all relevant details for every selected option are covered.Continue this pattern of refining the project scope until all relevant aspects have been covered. Your goal is to gather comprehensive information to accurately estimate the development cost.Ask follow-up questions for all selected options in sequence.

        Each question and the user's response must be formatted in JSON.
        Question response example is:
        "{"question":"What specific type of healthcare software are you developing?","options":["Electronic Health Record (EHR)","Telemedicine solution","Patient Management System","Medical Billing Software","Pharmacy Management System","Healthcare Analytics Tools","Wearable Health Tech Applications","Health Insurance Management Software","Appointment Scheduling Software"],"multiple":false}"

        The final cost estimation should be provided as plain text in markdown format in detail, based on a rate of $25 per hour.
        After gathering all the necessary information, the estimator will provide a detailed breakdown of the workflows involved. Each workflow should be presented as a separate point, with a time estimate in hours for completion. The final cost will be calculated based on a $25/hour rate, minimum total cost start from $1000.
        Markdown Example Workflow Breakdown:
        ### Workflow Breakdown:

        #### User Authentication Module:
        - **Time Estimate**: 20 hours
        - **Tasks**: Setting up login, registration, password reset, token validation.

        #### API Integration:
        - **Time Estimate**: 15 hours
        - **Tasks**: Connecting third-party APIs for payment processing.

        #### Frontend Development:
        - **Time Estimate**: 30 hours
        - **Tasks**: Building responsive UI, setting up user dashboards, dynamic forms.

        ### Final Cost Calculation:
        - **Total Hours**: 65 hours
        - **Rate**: $25/hour
        - **Total Cost**: $1,625

        Focus exclusively on essential project details, avoiding any unrelated questions
              `,
        },
        {
            role: "assistant",
            content: JSON.stringify({
                question: "What is the industry of your product?",
                options: [
                    "Auto And Vehicles",
                    "Dating",
                    "Healthcare",
                    "Banking",
                    "Finance And Insurance",
                    "Manufacturing",
                    "Transportation",
                    "Retail and wholesale",
                    "Oil and gas",
                    "Government And Non-Profit",
                    "Professional services",
                    "Telecommunications",
                    "Engineering And Construction",
                    "Utilities",
                    "Real Estate",
                    "Entertainment",
                    "E-commerce",
                    "Education",
                    "Social Media",
                    "Travel",
                    "Food And Drink",
                ],
                multiple: false,
            }),
        },
    ]);
    const [currentQuestion, setCurrentQuestion] = useState({
        question: "What is the industry of your product?",
        options: [
            "Auto And Vehicles",
            "Dating",
            "Healthcare",
            "Banking",
            "Finance And Insurance",
            "Manufacturing",
            "Transportation",
            "Retail and wholesale",
            "Oil and gas",
            "Government And Non-Profit",
            "Professional services",
            "Telecommunications",
            "Engineering And Construction",
            "Utilities",
            "Real Estate",
            "Entertainment",
            "E-commerce",
            "Education",
            "Social Media",
            "Travel",
            "Food And Drink",
        ],
        multiple: false,
    });
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone_number: "",
        company_name: "",
        project_description: "",
    });
    const [conversationId, setConversationId] = useState("");
    const conversationUrl = process.env.REACT_APP_API_URL + "conversation";
    const userDataUrl = process.env.REACT_APP_API_URL + "user";
    const [additionalInput, setAdditionalInput] = useState(""); // New state for text input
    const [finalEstimate, setFinalEstimate] = useState("");
    useEffect(() => {
        const lastMessage = conversationHistory[conversationHistory.length - 1].content;
        try {
            const parsedMessage = JSON.parse(lastMessage);
            if (parsedMessage.question) {
                setCurrentQuestion(parsedMessage);
                setFinalEstimate("");
            } else {
                setFinalEstimate(parsedMessage);
            }
        } catch (error) {
            setFinalEstimate(lastMessage);
        }
    }, [conversationHistory]);

    const handleCheckboxChange = (id, type) => {
        if (type === true) {
            setIsChecked((prev) => {
                if (prev.includes(id)) {
                    return prev.filter((item) => item !== id);
                } else {
                    return [...prev, id];
                }
            });
        } else {
            setIsChecked([id]);
        }
    };

    const handleSubmit = async (event) => {
        const uid1 = localStorage.getItem("uniqueKey");
        event.preventDefault();
        if (isChecked.length === 0 && !additionalInput.trim()) {
            setErrorMessage("Please select an option that suits your project. If none are suitable, then type 'None' in the textbox.");
            return false;
        }
        setErrorMessage("");
        setLoading(true);
        const selectedOption = isChecked.map((index) => currentQuestion?.options[index]).join(",");
        const userAnswer = `${selectedOption}: ${additionalInput}`; // Combine selected option and text input

        const updatedHistory = [...conversationHistory, { role: "user", content: userAnswer }];

        try {
            const response = await axios.post(conversationUrl, { conversationHistory: updatedHistory, uid: uid1, id: conversationId });
            console.log("API Response:", response.data.response); // Log the API response

            const newAssistantMessage = response.data.response;
            const converId = response.data.id;
            setConversationId(converId);

            if (response?.data?.response?.uuid) {
                setEmailOpen(true);
                return;
            }
            // Add the assistant's new message to the conversation history
            const newHistory = [...updatedHistory, { role: "assistant", content: JSON.stringify(newAssistantMessage) }];
            setConversationHistory(newHistory);

            // Reset the user's response and additional input
            setIsChecked([]);
            setAdditionalInput("");

            // Log the updated conversation history
            console.log("Updated Conversation History:", newHistory);
        } catch (error) {
            console.error("Error:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const contactSubmit = async (event) => {
        const uid1 = localStorage.getItem("uniqueKey");
        event.preventDefault();
        setLoading(true);
        if (!formData.name) {
            setFormErrorMessage("Name is required");
            return false;
        }
        if (!formData.email) {
            setFormErrorMessage("Email is required");
            return false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setFormErrorMessage("Email is invalid");
            return false;
        }
        const newFormData = { ...formData, uid: uid1, id: conversationId };
        try {
            const response = await axios.post(userDataUrl, newFormData);
            console.log("API Response:", response.data.response); // Log the API response

            const newAssistantMessage = response.data.response;
            const converId = response.data.id;
            setConversationId(converId);

            if (response?.data?.response?.uuid) {
                setEmailOpen(true);
                return;
            }
            // Add the assistant's new message to the conversation history
            const newHistory = [...conversationHistory, { role: "assistant", content: JSON.stringify(newAssistantMessage) }];
            setConversationHistory(newHistory);

            // Reset the user's response and additional input
            setIsChecked([]);
            setAdditionalInput("");

            setEmailOpen(false);
            setFormData({
                name: "",
                email: "",
                phone_number: "",
                company_name: "",
                project_description: "",
            });
            // Log the updated conversation history
            console.log("Updated Conversation History:", newHistory);
        } catch (error) {
            console.error("Error:", error.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Grid>
            {emailOpen ? (
                <ContactRightSide contactSubmit={contactSubmit} formData={formData} setFormData={setFormData} formErrorMessage={formErrorMessage} isSmLaptop={isSmLaptop} isMobile={isMobile}/>
            ) : loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "700px" }}>
                    <CircularProgress sx={{ color: "black" }} thickness={4} />
                </Box>
            ) : (
                <Item sx={{ padding: isSmLaptop?"44px 24px":isMobile?"44px 16px":"44px 45px" }}>
                    {finalEstimate ? (
                        <Grid sx={{ p: "16px 40px 30px", overflowY: "scroll", height: "700px" }} data-color-mode="light">
                            <Typography variant="h2" className="MontserratFont h2-text" style={{ color: "#000000" }} sx={{ py: 2 }}>
                                Final Estimation
                            </Typography>
                            <MDEditor.Markdown source={finalEstimate?.content} />
                        </Grid>
                    ) : (
                        <Grid>
                            <Typography variant="h2" className="MontserratFont h3-text" style={{ color: "#000000" }}>
                                {/* Which industry does your business belong to? */}
                                {currentQuestion?.question}
                            </Typography>
                            <Typography variant="body" className="" style={{ color: "#ed6161", paddingY: "10px" }}>
                                {errorMessage}
                            </Typography>
                            {currentQuestion?.options ? (
                                <Grid sx={{ mt: "36px", display: "flex", gap: "11px", flexWrap: "wrap", maxHeight: "500px", overflowY: "scroll" }}>
                                    {currentQuestion?.options.map((BtnData, index) => (
                                        <FormGroup sx={{ display: "flex", gap: "17px", flexDirection: "row" }} key={index}>
                                            <Button
                                                className="checkBtn"
                                                onClick={() => handleCheckboxChange(index, currentQuestion?.multiple)}
                                                sx={{
                                                    backgroundColor: isChecked.includes(index) ? "#17305D" : "initial",
                                                    color: isChecked.includes(index) ? "white !important" : "#262626 !important",
                                                    "&:hover": {
                                                        background: isChecked.includes(index) ? "#17305D !important" : "initial",
                                                    },
                                                }}
                                            >
                                                {currentQuestion?.multiple === false ? (
                                                    <FormControlLabel
                                                        onClick={(e) => e.stopPropagation()}
                                                        style={{ gap: "11px", textTransform: "capitalize" }}
                                                        control={
                                                            <Radio
                                                                checked={isChecked.includes(index) ? true : false}
                                                                onChange={() => handleCheckboxChange(index, currentQuestion?.multiple)}
                                                                style={{
                                                                    color: "#3DCED9",
                                                                    fontSize: "55px",
                                                                    padding: "0px",
                                                                }}
                                                            />
                                                        }
                                                        label={BtnData}
                                                    />
                                                ) : (
                                                    <FormControlLabel
                                                        onClick={(e) => e.stopPropagation()}
                                                        style={{ gap: "11px", textTransform: "capitalize" }}
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked.includes(index) ? true : false}
                                                                onChange={() => handleCheckboxChange(index, currentQuestion?.multiple)}
                                                                style={{
                                                                    color: "#3DCED9",
                                                                    fontSize: "55px",
                                                                    padding: "0px",
                                                                }}
                                                            />
                                                        }
                                                        label={BtnData}
                                                    />
                                                )}
                                            </Button>
                                        </FormGroup>
                                    ))}
                                </Grid>
                            ) : (
                                <Grid>
                                    <Typography variant="h3" className="MontserratFont h3-text" style={{ color: "#000000" }}>
                                        Loading question...
                                    </Typography>
                                </Grid>
                            )}
                            <Grid sx={{ mt: "24px" }}>
                                <Typography variant="h3" className="DmFont para-text" style={{ color: "#000000" }}>
                                    If you have any other specifications that are not listed in the options, or if no suitable option is provided, please describe them here:
                                </Typography>
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                    <TextField
                                        className="LableStyleTextFeild"
                                        InputProps={{
                                            style: {},
                                        }}
                                        sx={{
                                            width: "100%",
                                        }}
                                        value={additionalInput}
                                        onChange={(e) => setAdditionalInput(e.target.value)}
                                        id="filled-basic-email"
                                        variant="outlined"
                                        type="email"
                                        name="email"
                                        placeholder="Others, please specify"
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "11px",
                                    position: "absolute",
                                    bottom: "45px",
                                    right: "44px",
                                }}
                            >
                                <Button variant="contained" className="NextBtn" onClick={(e) => handleSubmit(e)}>
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Item>
            )}
        </Grid>
    );
};

export default Index;
